import React from "react";
import Seo from "../components/seo";
import Layout from "../components/layout";
import ContactsInfo from "../components/ContactsInfo";

const Contacts = () => (
  <Layout>
    <Seo title="Контакти"/>
    <ContactsInfo/>
  </Layout>
);

export default Contacts;