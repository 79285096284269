import React from "react";
import Title from "../Common/Title";
import ContactsForm from "./ContactsForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFacebookF} from "@fortawesome/free-brands-svg-icons";

const ContactsInfo = () => (
  <div className="panel my-5">
    <section className="contacts">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="section-title">
              <Title position="left">Контакти</Title>
              <br/>
              {/*<p>I design and develop services for customers of all sizes, specializing in creating stylish, modern websites</p>*/}
            </div>
          </div>
        </div>
        <div className="row flex-row-reverse">
          <div className="col-md-7 col-lg-8 m-15px-tb contact-name">
            <h5>Надіслати повідомлення</h5>
            <div className="contacts-form-wrapper">
              <ContactsForm />
            </div>
          </div>
          <div className="col-md-5 col-lg-4 m-15px-tb">
            <div className="contact-name">
              <h5>Електронна пошта</h5>
              <p>medlaser.ck.ua@gmail.com</p>
            </div>
            <div className="contact-name">
              <h5>Адреса</h5>
              <p>
                м. Черкаси,<br/>
                вул. Дахнівська, буд. 50/12А
              </p>
            </div>
            <div className="contact-name">
              <h5>Режим роботи</h5>
              <p>
                Понеділок-П'ятниця<br/>
                9:00 - 17:00
              </p>
            </div>
            <div className="contact-name">
              <h5>Контакти</h5>
              {/*<p>+38 (0472) 38-52-54</p>*/}
              <p>+38 (073) 016-08-19</p>
              <p>+38 (068) 518-63-18</p>
            </div>

            <div className="social-share nav">
              {/*<a className="dribbble" href="#">*/}
              {/*  <i className="fab fa-dribbble"></i>*/}
              {/*</a>*/}
              <a href="https://www.facebook.com/groups/321245798956405" target="_blank" rel="noreferrer">
                <FontAwesomeIcon icon={faFacebookF} className="fb" /> Наша Facebook сторінка
              </a>
              {/*<a className="linkedin" href="#">*/}
              {/*  <i className="fab fa-linkedin-in"></i>*/}
              {/*</a>*/}
            </div>
          </div>
          <div className="col-12 mt-5">
            <iframe
              title="google-maps"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10372.851960618225!2d32.0264611!3d49.4615901!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x10c6b57073b0fbfb!2z0JDQutCw0LTQtdC80LjRjyDQu9Cw0LfQtdGA0L3QvtC5INC80LXQtNC40YbQuNC90Ys!5e0!3m2!1sen!2sua!4v1627230299188!5m2!1sen!2sua"
              width="100%" height="450px" style={{borderRadius: 12}} allowFullScreen="" loading="lazy"></iframe>
          </div>
        </div>
      </div>
    </section>
  </div>
);

export default ContactsInfo;